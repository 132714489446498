.card-block {
  box-shadow: 0 4px 10px 5px #0000001A;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 40px;
  background-color: $white;
  .selector-chevron {
    color: #00000066;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}

.background {
  background-image: url('@/assets/iStock-1318400874 1.png');
  background-size: cover;
  background-repeat: no-repeat;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.logo-block {
  padding-top:40px;
  img {
    height: 35px;
    width: auto;
    margin: 20px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}
.footer {
  padding: 12px;
  background-color: $theme-secondary;
  color: $black;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
  a {
    text-decoration: none;
    color: $black;
  }

  &--qualification {
    background-color: var(--secondary, $theme-secondary);
    color: var(--footer, $black);

    a {
      color: var(--footer, $black);
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.enquiry-date {
  font-size: 18px;
  font-weight: 400;
  &--small {
    font-weight: 400;
    font-size: 18px;
  }
}

.jumbo {
  background-color: $light-blue;
  text-align: center;
  padding: 60px 10px 50px;
  &__heading {
    font-weight: 700;
    font-size: 48px;
    line-height: 58.09px;
    color: $white;
    &__name {
      font-weight: 400;
    }
  }
}