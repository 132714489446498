@import "@/scss/variables.scss";

  .nav {
    &-link {
      text-wrap: nowrap;
    }

    &__thin {
      height: 60px;
    }
  }

  .logo {
    max-width: 200px;
  }
