.bg-theme-primary {
  background: $theme-primary;
}

.navbar {
  height: 100px;
  &-nav {
    padding: 0 20px;
    background: var(--primary, $theme-primary);
    .nav-link {
      color: $white;
      font-weight: $font-weight-bold;
      font-size: $nav-font-size;
      line-height: $nav-font-line-height;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  &__hide {
    @media screen and (min-width: 992px) {
      position: absolute;
      display: none;
    }
  }
}

.in-page-nav {
  margin-top: 20px;
  &-link {
    text-decoration: none;
    color: $secondary-button-color;
    font-size: 24px;
    font-weight: 400;
    line-height: 29.05px;
    padding-top: 8px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.qualification {
  background: var(--primary, $theme-primary);
  display: flex;
  justify-content: center;

  .qualification__container {
    justify-content: center;
    position: relative;

    .navbar-expand-lg .navbar-collapse {
      @media screen and (min-width: 992px) {
        display: none!important;
      }
    }
  }

  .qualification__toggler {
    position: absolute;
    left: 0;
    @media screen and (min-width: 992px) {
      display: none!important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}