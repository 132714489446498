.login-form {
  padding: 50px 200px;
  text-align: center;
  @media screen and (max-width: 768px) {
      padding: 50px 10px;
      text-align: center;
  }
}

.form-prompt {
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.progress {
  background-color: #ebebeb;
  border-radius: 10px;
  height: 20px;
  padding: 4px;
  .progress-bar {
    width: v-bind(percentage);
    background-color: #646464;
    border-radius: 10px;
  }
}

.form-control {
  appearance: auto;
}