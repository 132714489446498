.separated {
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 2px solid $theme-primary;
  }
}

.text-highlighted {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.text-md-highlighted {
  @media screen and (min-width: 768px) {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
}

.enquiry-heading {
  font-size: 24px;
  font-weight: 700;
}
.enquiry-date {
  font-size: 18px;
  font-weight: 400;
}