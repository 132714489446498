$identifier: 'phone-verification';
$class: '.' + $identifier;

#{$class}-container {
  #{$class} {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 48px;
    gap: 16px;

    @media screen and (min-width: 992px) {
      gap: 32px;
    }

    #{$class}__header {
      text-align: center;
      margin-bottom: 0;
    }

    #{$class}__inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;

      @media screen and (min-width: 992px) {
        flex-direction: row;
      }
    }

    #{$class}__input-row {
      @media screen and (max-width: 991px) {
        width: 100%;

        .btn,
        .form-control {
          width: 100%;
        }
      }
    }

    #{$class}__header {
      margin-bottom: 0;
    }

    #{$class}__retry {
      margin-bottom: 0;
    }
  }
}
