.btn-orange {
  color: $primary-button-text-color;
  font-weight: $primary-button-font-weight;
  background-color: $primary-button-color;
  padding: 14px 45px 12px;
  line-height: 19px;
  &:hover {
    background: $primary-button-hover-color;
    color: $primary-button-text-color;
  }
  .disabled {
    background: $primary-button-disabled-color;
    color: $primary-button-text-color;
  }

  @media screen and (max-width: 768px) {
    padding: 14px 20px 12px;
  }

  &__qualification {
    background-color:  var(--button, $primary-button-color)!important;
    min-height: 56px;
    min-width: 195px;
    padding: 20px 8px;
    font-size: 24px;
    line-height: 26px;
    word-break: break-word;

    &:hover,
    &:active {
      background-color: var(--button-hover, $primary-button-color)!important;
      color: $primary-button-text-color!important;
      border: none;
    }

    @media screen and (max-width: 992px) and (min-width: 769px) {
      padding: 16px 15px
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 22px;
      padding: 20px 8px;
      width: 45%;
    }

    @media screen and (max-width: 416px) {
      min-width: unset;
      width: 40%;
    }
  }
}

.btn-blob {
  height: 100%;
}

.btn-feedback {
  background: #3C1E5F;
  color: #fff;
  font-weight: 700;
  transition: color .5s ease-in-out ,background .5s ease-in-out;

  &:hover {
    background: #b496dc;
    color: #000;
  }
}

.selector-chevron {
  color: $secondary-button-color;
  font-weight: 700;
  font-size: 24px;
}

.cursor-pointer {
  cursor: pointer;
}