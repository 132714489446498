$identifier: 'qualification-footer';
$class: '.' + $identifier;

#{$class} {

  @media screen and (max-width: 767px) {
    max-width: 700px!important;
  }

  &__top {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      gap: 16px;
      justify-content: center;
    }

    &-group {
      width: fit-content;

      &--secondary {
        @media screen and (max-width: 648px) {
          display: none;
        }
      }

      & a {
        @media screen and (max-width: 350px) {
          font-size: 14px;
        }
      }
    }
  }
}
