.block-title {
  font-size: 32px;
  line-height: 38.73px;
  font-weight: $font-weight-bold;
  color: $theme-primary;
  margin: 25px 0 40px;
  text-decoration: none;
}

.header-secondary {
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.enquiry-heading {
  font-size: 24px;
  font-weight: 700;
  &--small {
    font-weight: 700;
    font-size: 18px;
  }
}