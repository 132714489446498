.table {
  thead {
    tr {
      th {
        background-color: $table-header-colour;
        font-size: $nav-font-size;
        font-weight:$table-header-font-weight;
        line-height: $nav-font-line-height;
        font-style: italic;
        padding: 12px 10px 8px; ;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 20px 10px;
        font-size: $nav-font-size;
        line-height: $nav-font-line-height;
      }
    }
  }
}