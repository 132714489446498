@import "@/scss/variables.scss";

.icon {
  &--green {
    color: #00B007;
  }
  &--orange {
    color: #FF7A00;
  }
  &--red {
    color: #FF0000;
  }
}


