.modal-content{
  background-color: #EEF8FC;
  .modal-header {
    border-bottom: none !important;
    .close-button {
      color: red !important;
      font-size: 24px;
    }
  }
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}